import jQuery from 'jquery'

/* eslint-disable */
var changePassword = function(e) {
	console.log(e);
	e.preventDefault();
	var formData = new FormData();

	var data = {
		mail: document.querySelector('input[name=email]').value,
		current_password:	document.querySelector('input[name=current_password]').value,
		new_password:	document.querySelector('input[name=new_password]').value,
		confirm_new_password:	document.querySelector('input[name=confirm_new_password]').value,
	}

	Object.entries(data).forEach(function(x) {
		console.log(x);
		formData.append(x[0], x[1]);
	});

	var cpe = document.querySelector("#changePasswordError");
	var cpo = document.querySelector("#changePasswordOk");
	cpe.style.display = "none";
	cpo.style.display = "none";

	fetch('/api/changePassword', {
		method: "POST",
		body: formData
	})
	.then(function(x) { return x.json() })
	.then(function(response) {
		if(response.error)
		{
			cpe.style.display = "block";
			cpe.innerHTML = response.error;
		}
		if(response.success) {
			cpo.style.display = "block";
			cpo.innerHTML = "Passordet er blitt byttet";
		}
	})
	.catch(console.error);
};
jQuery(window).load(function() {
	setTimeout(function(){
		jQuery('.mianbanner').addClass('active');
	}, 1000);

});

let requestPassword = function() {
	var input = document.querySelector("#reqPasswordField").value;

	if(/^[A-Za-z0-9\-_\+\.æøå]+@[A-Za-z0-9\-_\.æøå]+\.[A-Za-z0-9\-_\.æøå]+$/.exec(input) === null) {
		document.querySelector("#passwordErrorMsg").innerHTML = "Dette er ikke en gyldig epost";
		return;
	}
	console.log(input);

	var fd = new FormData();

	fd.append('email', input);
	fetch("/api/requestPassword", {
		method: "POST",
		body: fd
	})
	.then(function(response) {
		if(response.status === 403)
			throw Exception("Something went wrong");
		$("#passwordDialog").modal("hide");
		document.querySelector("#passwordOK").style.display = "block";
		document.querySelector("#passwordErrorMsg").innerHTML = "";
	})
	.catch(function() {
		document.querySelector("#passwordErrorMsg").innerHTML = "En uventet feil oppsto";
	});
}
