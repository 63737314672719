/* eslint-disable */
class TabSwapper {
  constructor(root) {
    this.root = root;
    this.bar = root.querySelector(".tab-bar");
    this.view = root.querySelector(".tab-view");
    this._currentTab = "tab1";

    this.currentTab = localStorage.getItem("currentItem") || this.view.querySelector("div").id;

    this.bar.querySelectorAll("a[data-tab]").forEach(a => {
      a.addEventListener("click", (event) => this.currentTab = event.target.getAttribute("data-tab"))
    })
  }

  set currentTab(tab) {
    Array.from(this.view.children)
      .forEach(child => child.classList.toggle("active", child.id === tab));
    this.bar.querySelectorAll("a").forEach(a => a.classList.toggle("active", a.getAttribute("data-tab") === tab));
    localStorage.setItem("currentItem", tab);
    this._currentTab = tab;

  }
  get currentTab() {
    return this._currentTab;
  }

}

const tabbis = document.querySelector("#tabbis")
if (tabbis) {
  tabbis.tab = new TabSwapper(tabbis);
}
