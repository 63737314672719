/* eslint-disable */
class BrregSearcher {
  constructor(root) {
    // Inject itself into the DOM object
    this.root = root;
    root.searcher = this;

    this.state = "hidden";

    this.brName = root.querySelector("#brreg_name");
    this.numField = root.querySelector("#brreg_nr")
    this.searchField = root.querySelector(".search-field");
    this.numField.addEventListener('keyup', function (event) {
      if(this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(function(event){
        if(event.target.value === "") {
          return;
        }
        this.searchFor(event.target.value, 'nr');
      }.bind(this), 200, event);
    });

    this.brName.addEventListener('keyup', function (event) {
      if(this.searchTimeout)
        clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(function(event) {
        if(event.target.value.length < 1)
        {
          this.state = "hidden";
        }


        this.searchFor(event.target.value, 'name')
      }.bind(this), 200, event);
    })
  }

  /**
   * Search BrReg
   *
   * type can be 'name' or 'nr'
   * @param {String|Number} query
   * @param {String} type
   */
  searchFor(query, type) {
    if(type === "nr") {
      fetch("https://data.brreg.no/enhetsregisteret/api/enheter/" + encodeURIComponent(query))
      .then(response => response.json())
      .then(response => {
        if(response.navn)
          this.brName.value = response.navn;
      })
      .catch(console.warn)
      .finally(this.state = "hidden");
    } else if(type == "name") {
      this.state = "loading";
      fetch("https://data.brreg.no/enhetsregisteret/api/enheter?navn=" + encodeURIComponent(query) +  "&konkurs=false")
      .then(response => response.json())
      .then(response => {
        console.log(response)
        if(response.page.totalElements > 0) {
          this.results = response._embedded.enheter;
        } else {
          this.results = [];
        }
      })
      .catch(console.warn)
      .finally(() => this.state = "show");
    }
  }

  set results(value) {
    console.log("Value", value);
    this._results = value;
    let listContainer = this.searchField.querySelector("ul");
    if(value.length == 0) {
      listContainer.innerHTML = '<li class="nores"><b>Ingen resultater</b></li>';
      return;
    }

    listContainer.innerHTML = "";
    this._results.forEach(result => {
      let li = document.createElement("li");
      let a = document.createElement("a");
      a.text = `${result.organisasjonsnummer} - ${result.navn}`;
      a.addEventListener('click', () => {
        this.numField.value = result.organisasjonsnummer;
        this.brName.value = result.navn;
        this.state = "hidden";
      });
      li.appendChild(a);
      listContainer.appendChild(li);
    })
  }

  get results() {
    return this._results;
  }

  set state(value) {
    if(!this.searchField)
      this.searchField = this.root.querySelector(".search-field");
    let states = ["loading", "show", "hidden", "error"];
    if(states.indexOf(value) !== -1)
      this._state = value;
    states.forEach(state => {
      this.searchField.classList.toggle(state, state === value);
    });
  }

  get state() {
    return this._state;
  }
}

const bedriftFrom = document.querySelector("#bedrift-form")

if (bedriftFrom) {
  setTimeout(() => {
    new BrregSearcher(bedriftFrom);
  }, 200);
}
